import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useHousehold } from "../../context/HouseholdContext";
import { useInvitations } from "../../context/InvitationContext";
import { useUser } from "../../context/UserContext";
import Loading from "../Loading/Loading";
import AddHouseholdsDialog from "./AddHouseholdDialog";
import InvitationList from "./InvitationList";

const HouseholdScreen = () => {
  const { signedInUser } = useUser();
  const { invitations } = useInvitations();

  const { availableHouseholds, setCurrentHousehold } = useHousehold(
    signedInUser?.id
  );

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  if (!availableHouseholds || !invitations) {
    return (
      <Loading
        sx={{
          margin: "auto",
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography sx={{ margin: 2 }}>Households</Typography>
      {availableHouseholds.map((household) => {
        return (
          <Card
            key={household.id}
            onClick={() => {
              setCurrentHousehold(household);
            }}
            sx={{ maxWidth: 345, margin: 1, width: "100%" }}
          >
            <CardActionArea>
              <CardContent>
                <Typography>{household.name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        );
      })}
      <Button onClick={() => setIsAddDialogOpen(true)}>
        Add new household
      </Button>
      <AddHouseholdsDialog
        isOpen={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
      />
      {invitations.length > 0 && (
        <InvitationList invitations={invitations} user={signedInUser} />
      )}
    </Box>
  );
};

export default HouseholdScreen;
