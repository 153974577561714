import Add from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useChartData } from "../../context/ChartDataContext";
import { useHouseholdForSignedInUser } from "../../context/HouseholdContext";
import { useUser } from "../../context/UserContext";
import { defaultUserColor } from "../../sync/FirebaseSync";
import { WriteAction } from "../../sync/FirestoreWrite";
import styles from "./styles.module.css";

const ChoreDayBox = ({ choreId, entries, entryDate, onEntryAdded }) => {
  const { signedInUser } = useUser();
  return (
    <Box className={styles.choreBlock}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {entries.map((entry) => {
          return <ChoreEntry key={entry.id} entry={entry} choreId={choreId} />;
        })}

        <IconButton
          onClick={(event) => {
            onEntryAdded(entryDate, signedInUser);
          }}
          sx={{
            marginLeft: "auto",
            marginTop: "auto",
          }}
        >
          <Add color="action" fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

const ChoreEntry = ({ choreId, entry }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { currentHousehold, currentHouseholdUsers: users } =
    useHouseholdForSignedInUser();

  const { dispatch } = useChartData();

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    handleClose();
    dispatch({
      type: WriteAction.DeleteEntry,
      payload: {
        householdId: currentHousehold.id,
        choreId: choreId,
        entryId: entry.id,
      },
    });
  };

  const entryUser = users?.find((user) => entry.userId === user.id);
  const displayName = entryUser ? entryUser.name : "Unknown user";

  const checkColor = entryUser?.color ? entryUser.color : defaultUserColor;

  return (
    <div>
      <IconButton onClick={() => setIsDialogOpen(true)}>
        <CheckIcon htmlColor={checkColor} />
      </IconButton>
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>Chore Entry</DialogTitle>
        <DialogContent>
          <DialogContentText>{displayName}</DialogContentText>
          <DialogActions>
            <Button onClick={handleDelete}>Delete</Button>
            <Button onClick={handleClose}>Ok</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChoreDayBox;
