import { createContext, useContext, useEffect, useState } from "react";
import { getInvitationsForEmailAddressObservable } from "../sync/InvitationDataSource";
import { useAuth } from "./AuthContext";

const InvitationContext = createContext();

export const InvitationProvider = ({ children }) => {
  const { authUser } = useAuth();

  const [invitations, setInvitations] = useState();

  useEffect(() => {
    if (!authUser?.email) return;

    const invitationsSubscription = getInvitationsForEmailAddressObservable(
      authUser.email
    ).subscribe((invites) => {
      //   console.log("Setting invitations", invites);
      setInvitations(invites);
    });

    return () => invitationsSubscription.unsubscribe();
  }, [authUser]);

  return (
    <InvitationContext.Provider value={{ invitations }}>
      {children}
    </InvitationContext.Provider>
  );
};

export const useInvitations = () => {
  const context = useContext(InvitationContext);
  if (context === undefined) {
    throw new Error("useInvitations must be used within an InvitationProvider");
  }
  return context;
};
