import {
  addDoc,
  setDoc,
  doc,
  deleteDoc,
  updateDoc,
  collection,
} from "firebase/firestore";
import {
  choresCollectionId,
  db,
  entiresCollectionId,
  householdsCollectionId,
  usersCollectionId,
} from "../firebaseConfig";

export const WriteAction = {
  AddChore: "AddChore",
  DeleteChore: "DeleteChore",
  RenameChore: "RenameChore",
  AddEntry: "AddEntry",
  DeleteEntry: "DeleteEntry",
};

export const firestoreWrite = (action) => {
  const householdId = action.payload.householdId;
  switch (action.type) {
    case WriteAction.AddChore:
      const chore = action.payload.chore;

      const docRef = doc(
        db,
        householdsCollectionId,
        householdId,
        choresCollectionId,
        String(chore.id)
      );

      setDoc(docRef, {
        name: chore.name,
      });

      break;
    case WriteAction.DeleteChore:
      deleteDoc(
        doc(
          db,
          householdsCollectionId,
          householdId,
          choresCollectionId,
          String(action.payload.choreId)
        )
      );
      break;
    case WriteAction.RenameChore:
      const renameDocRef = doc(
        db,
        householdsCollectionId,
        householdId,
        choresCollectionId,
        String(action.payload.choreId)
      );
      updateDoc(renameDocRef, { name: action.payload.choreName });
      break;
    case WriteAction.AddEntry:
      const choreId = action.payload.choreId;

      const currentUserId = action.payload.userId;
      const userRef = doc(db, usersCollectionId, currentUserId);
      const timeStamp = action.payload.timeStamp;
      const time = {
        year: timeStamp.getFullYear(),
        monthIndex: timeStamp.getMonth(),
        dayOfMonth: timeStamp.getDate(),
        hour: timeStamp.getHours(),
        minute: timeStamp.getMinutes(),
      };

      const entry = {
        user: userRef,
        time: time,
      };

      const entryCollection = collection(
        db,
        householdsCollectionId,
        householdId,
        choresCollectionId,
        choreId,
        entiresCollectionId
      );
      addDoc(entryCollection, entry);
      break;

    case WriteAction.DeleteEntry:
      const entryChoreId = String(action.payload.choreId);
      const entryId = action.payload.entryId;
      const entryRef = doc(
        db,
        householdsCollectionId,
        householdId,
        choresCollectionId,
        entryChoreId,
        entiresCollectionId,
        entryId
      );
      deleteDoc(entryRef);
      break;

    default:
      break;
  }
};

export const addNewHousehold = async (userId, name) => {
  try {
    const userDoc = doc(db, usersCollectionId, userId);
    const householdCollection = collection(db, householdsCollectionId);
    await addDoc(householdCollection, {
      users: [userDoc],
      name: name,
    });
  } catch (error) {
    console.log("Error adding household", error);
  }
};
