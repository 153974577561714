export const millisPerDay = 1000 * 60 * 60 * 24;

export const getStartOfWeekForDate = (date) => {
  const startDate = new Date(date.getTime() - date.getDay() * millisPerDay);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  return startDate;
};

export const getShiftedDate = (date, millisToShift) => {
  return new Date(date.getTime() + millisToShift);
};

export const getShiftedByDaysDate = (date, daysToShift) => {
  const newDate = new Date(date.getTime());
  const newDateDayOfMonth = newDate.getDate() + daysToShift;
  newDate.setDate(newDateDayOfMonth);
  return newDate;
};
