import { getAuth } from "@firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { firebaseApp } from "../firebaseConfig";

const AuthContext = createContext();

const AuthState = {
  Loading: "Loading",
  SignedOut: "SignedOut",
  SignedIn: "SignedIn",
};

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(AuthState.Loading);
  const [authUser, setAuthUser] = useState();

  useEffect(() => {
    const unregisterAuthObserver = getAuth(firebaseApp).onAuthStateChanged(
      (user) => {
        setAuthUser(user);
        const state = user ? AuthState.SignedIn : AuthState.SignedOut;
        setAuthState(state);
      }
    );
    return () => unregisterAuthObserver();
  }, []);

  return (
    <AuthContext.Provider value={{ authState, authUser }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth, AuthState };
