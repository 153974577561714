import { Box } from "@mui/material";
import { useChartData } from "../../context/ChartDataContext";
import Loading from "../Loading/Loading";
import Chart from "./Chart";
import styles from "./styles.module.css";

const ChartScreen = () => {
  const { chartData } = useChartData();

  if (!chartData) {
    return (
      <Loading
        sx={{
          margin: "auto",
        }}
      />
    );
  }

  return (
    <Box
      // className={styles.chartScreen}
      sx={{
        display: "flex",
        flexFlow: "column",
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
        overflow: "scroll",
      }}
    >
      <Chart chartData={chartData} />
    </Box>
  );
};

export default ChartScreen;
