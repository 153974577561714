import {
  addDoc,
  collection,
  doc,
  query,
  runTransaction,
  updateDoc,
  where,
} from "@firebase/firestore";
import { collectionData, docData } from "rxfire/firestore";
import { combineLatest, map, of, switchMap } from "rxjs";
import {
  db,
  householdsCollectionId,
  invitationsCollectionId,
  usersCollectionId,
} from "../firebaseConfig";

export const getInvitationsForEmailAddressObservable = (emailAddress) => {
  const invitationsCollection = collection(db, invitationsCollectionId);
  const invitationsByEmailAddressQuery = query(
    invitationsCollection,
    where("recipientEmail", "==", emailAddress),
    where("accepted", "==", null)
  );
  const observable = collectionData(invitationsByEmailAddressQuery, {
    idField: "id",
  }).pipe(
    switchMap((invitations) => {
      if (invitations.length === 0) {
        return of([]);
      }
      const invitationsObservables = invitations.map((invitation) => {
        const householdDocRef = doc(
          db,
          householdsCollectionId,
          invitation.household.id
        );
        return docData(householdDocRef).pipe(
          map((household) => {
            return {
              id: invitation.id,
              householdName: household.name,
              householdId: householdDocRef.id,
              recipientEmail: invitation.recipientEmail,
            };
          })
        );
      });

      return combineLatest(invitationsObservables);
    })
  );
  return observable;
};

export const acceptInvitation = async (invitation, user) => {
  const householdRef = doc(db, householdsCollectionId, invitation.householdId);
  const invitationRef = doc(db, invitationsCollectionId, invitation.id);
  try {
    await runTransaction(db, async (transaction) => {
      const householdDoc = await transaction.get(householdRef);
      if (!householdDoc.exists()) {
        throw "Document does not exist!";
      }

      const userRef = doc(db, usersCollectionId, user.id);

      const newUsersList = householdDoc.data().users;
      newUsersList.push(userRef);
      transaction.update(householdRef, { users: newUsersList });

      transaction.update(invitationRef, { accepted: true });
    });
    console.log("Transaction successfully committed!");
  } catch (e) {
    console.log("Transaction failed: ", e);
  }
};

export const ignoreInvitation = async (invitation) => {
  const invitationDocRef = doc(db, invitationsCollectionId, invitation.id);
  await updateDoc(invitationDocRef, { accepted: false });
};

export const sendInvitation = async (
  household,
  recipientEmailAddress,
  currentUser
) => {
  const invitationsCollection = collection(db, invitationsCollectionId);
  const householdDocRef = doc(db, householdsCollectionId, household.id);
  const currentUserDocRef = doc(db, usersCollectionId, currentUser.id);

  await addDoc(invitationsCollection, {
    household: householdDocRef,
    recipientEmail: recipientEmailAddress,
    accepted: null,
    sender: currentUserDocRef,
  });
};
