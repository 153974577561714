import { Avatar, Box, Menu, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import { useHouseholdForSignedInUser } from "../../context/HouseholdContext";

const ProfileMenuButton = ({
  user,
  onLogOut,
  onUserSettingsSelected,
  onInviteUserSelected,
}) => {
  const secondName = user.name.split(" ")[1];
  const secondInitial = secondName ? secondName[0] : "";
  const userInitials = `${user.name.split(" ")[0][0]}${secondInitial}`;

  const anchorRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <Avatar ref={anchorRef} onClick={() => setIsOpen(true)}>
        {userInitials}
      </Avatar>
      <ProfileMenu
        user={user}
        open={isOpen}
        anchorRef={anchorRef.current}
        handleClose={() => setIsOpen(false)}
        onLogOut={onLogOut}
        onUserSettingsSelected={onUserSettingsSelected}
        onInviteUserSelected={onInviteUserSelected}
      />
    </Box>
  );
};

const ProfileMenu = ({
  user,
  anchorRef,
  open,
  handleClose,
  onLogOut,
  onUserSettingsSelected,
  onInviteUserSelected,
}) => {
  const { currentHousehold, setCurrentHousehold } =
    useHouseholdForSignedInUser();

  return (
    <Menu
      //   id="basic-menu"
      anchorEl={anchorRef}
      open={open}
      onClose={handleClose}
      //   MenuListProps={{
      //     "aria-labelledby": "basic-button",
      //   }}
    >
      <MenuItem
        onClick={() => {
          onUserSettingsSelected();
          handleClose();
        }}
      >
        Settings
      </MenuItem>
      {currentHousehold && (
        <MenuItem
          onClick={() => {
            setCurrentHousehold(null);
            handleClose();
          }}
        >
          Change household
        </MenuItem>
      )}
      {currentHousehold && (
        <MenuItem
          onClick={() => {
            onInviteUserSelected();
            handleClose();
          }}
        >
          Invite user to household
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          onLogOut();
          handleClose();
        }}
      >
        Logout {user.name}
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenuButton;
