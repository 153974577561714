import { AppBar, Toolbar, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import { useState } from "react";
import ChartScreen from "./components/Chart/ChartScreen";
import HouseholdScreen from "./components/Household/HouseholdScreen";
import Loading from "./components/Loading/Loading";
import LoginScreen from "./components/Login/LoginScreen";
import InviteUserDialog from "./components/UserSettings/InviteUserDialog";
import ProfileMenuButton from "./components/UserSettings/ProfileMenuButton";
import UserSettingsDialog from "./components/UserSettings/UserSettingsDialog";
import { AuthState, useAuth } from "./context/AuthContext";
import { ChartDataProvider } from "./context/ChartDataContext";
import { useHousehold } from "./context/HouseholdContext";
import { InvitationProvider } from "./context/InvitationContext";
import { useUser } from "./context/UserContext";
import "./styles.css";

const PageContent = () => {
  const { authState, authUser } = useAuth();

  switch (authState) {
    case AuthState.Loading:
      return (
        <Loading
          sx={{
            margin: "auto",
          }}
        />
      );
    case AuthState.SignedOut:
      return <LoginScreen />;
    case AuthState.SignedIn:
      return <Screens authUser={authUser} />;
  }
};

const Screens = ({ authUser }) => {
  const { currentHousehold } = useHousehold(authUser?.uid);

  if (!currentHousehold) {
    return (
      <InvitationProvider>
        <HouseholdScreen />
      </InvitationProvider>
    );
  }

  return (
    <ChartDataProvider>
      <ChartScreen sx={{ flexGrow: 1, flexShrink: 1, flexBasis: "auto" }} />
    </ChartDataProvider>
  );
};

const ChoreChartAppBar = ({ signInState, signedInUser }) => {
  const [isUserSettingsDialogOpen, setIsUserSettingsDialogOpen] =
    useState(false);

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);

  return (
    <AppBar
      position="static"
      sx={{ flexGrow: 0, flexShrink: 1, flexBasis: "auto", zIndex: 100 }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          align="left"
          sx={{ flexGrow: 1 }}
        >
          Chore Chart
        </Typography>

        {signInState === AuthState.SignedIn && signedInUser && (
          <>
            <ProfileMenuButton
              user={signedInUser}
              onLogOut={() => getAuth().signOut()}
              onUserSettingsSelected={() => setIsUserSettingsDialogOpen(true)}
              onInviteUserSelected={() => setIsInviteDialogOpen(true)}
            />
            <UserSettingsDialog
              isOpen={isUserSettingsDialogOpen}
              onClose={() => setIsUserSettingsDialogOpen(false)}
            />
            {isInviteDialogOpen && (
              <InviteUserDialog
                isOpen={isInviteDialogOpen}
                onClose={() => setIsInviteDialogOpen(false)}
              />
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default function App() {
  const { signedInUser } = useUser();
  const { authState: signInState } = useAuth();

  return (
    <div className="App">
      <ChoreChartAppBar signInState={signInState} signedInUser={signedInUser} />
      <PageContent signInState={signInState} />
      {/* <Router>
          <Switch>
            <Route path="/login">
              <LoginScreen />
            </Route>
            <Route>
              <ChartScreen />
            </Route>
          </Switch>
        </Router> */}
    </div>
  );
}
