import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import {
  acceptInvitation,
  ignoreInvitation,
} from "../../sync/InvitationDataSource";

const InvitationList = ({ invitations, user }) => {
  return (
    <>
      <Typography sx={{ margin: 2 }}>Invitations</Typography>
      {invitations.map((invitation) => {
        return (
          <InvitationItem
            key={invitation.id}
            householdName={invitation.householdName}
            invitation={invitation}
            user={user}
          />
        );
      })}
    </>
  );
};

const InvitationItem = ({ householdName, invitation, user }) => {
  return (
    <Card sx={{ maxWidth: 345, margin: 1, width: "100%" }}>
      <CardContent>
        <Typography>{householdName}</Typography>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              acceptInvitation(invitation, user);
            }}
          >
            Accept
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              ignoreInvitation(invitation);
            }}
          >
            Ignore
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default InvitationList;
