import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useHouseholdForSignedInUser } from "../../context/HouseholdContext";
import { useUser } from "../../context/UserContext";
import { sendInvitation } from "../../sync/InvitationDataSource";

const InviteUserDialog = ({ isOpen, onClose }) => {
  const [currentEmailAddress, setCurrentEmailAddress] = useState();
  const { currentHousehold } = useHouseholdForSignedInUser();
  const { signedInUser } = useUser();

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Invite user to household</DialogTitle>
      <DialogContent>
        <TextField
          label="Email address"
          margin="dense"
          value={currentEmailAddress}
          onChange={(event) => {
            setCurrentEmailAddress(event.target.value);
          }}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            sendInvitation(currentHousehold, currentEmailAddress, signedInUser);
            onClose();
          }}
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserDialog;
