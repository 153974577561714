import { createContext, useContext, useEffect, useState } from "react";
import { getChoresAndEntriesForHouseholdObservable } from "../sync/FirebaseSync";
import { firestoreWrite } from "../sync/FirestoreWrite";
import { useHouseholdForSignedInUser } from "./HouseholdContext";

const ChartDataContext = createContext();

const ChartDataProvider = ({ children }) => {
  const [chartData, setChartData] = useState();
  const { currentHousehold } = useHouseholdForSignedInUser();

  useEffect(() => {
    if (currentHousehold?.id) {
      const dataSubscription = getChoresAndEntriesForHouseholdObservable(
        currentHousehold.id
      ).subscribe((state) => {
        setChartData(state);
      });
      return () => dataSubscription.unsubscribe();
    }

    setChartData(null);
  }, [currentHousehold]);

  return (
    <ChartDataContext.Provider value={{ chartData, dispatch: firestoreWrite }}>
      {children}
    </ChartDataContext.Provider>
  );
};

const useChartData = () => {
  const context = useContext(ChartDataContext);
  if (context === undefined) {
    throw new Error("useChartData must be used within a ChartDataProvider");
  }
  return context;
};

export { ChartDataProvider, useChartData };
