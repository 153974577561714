import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCbRMB9eTLz2CfbF257kjeB_CiSpH7HM8U",
  authDomain: "chore-chart-fc1b8.firebaseapp.com",
  projectId: "chore-chart-fc1b8",
};

export const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);

export const householdsCollectionId = "household";

export const choresCollectionId = "chores";

export const entiresCollectionId = "entries";

export const usersCollectionId = "users";

export const invitationsCollectionId = "invitations";
