import { Box, Button, Icon, IconButton, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useChartData } from "../../context/ChartDataContext";
import {
  getShiftedByDaysDate,
  getShiftedDate,
  getStartOfWeekForDate,
  millisPerDay,
} from "../../DateUtils";
import { WriteAction } from "../../sync/FirestoreWrite";
import ChoreDayBox from "./ChoreDayBox";
import ChoreControl from "./ChoreControl";
import styles from "./styles.module.css";
import UndoSnackbar from "./UndoSnackbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHouseholdForSignedInUser } from "../../context/HouseholdContext";

const daysOfTheWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const addNewChore = (householdId, state) => {
  const nextChoreId =
    state.reduce((a, b) => {
      return Math.max(a, b.id);
    }, -1) + 1;
  const newChore = {
    id: nextChoreId,
    name: null,
    entries: [[], [], [], [], [], [], []],
  };

  return {
    type: WriteAction.AddChore,
    payload: {
      householdId: householdId,
      chore: newChore,
    },
  };
};

const Chart = ({ chartData }) => {
  const { dispatch } = useChartData();

  // const [previousState, setPreviousState] = useState(null);
  const { currentHousehold } = useHouseholdForSignedInUser();

  const [weekStartDate, setWeekStartDate] = useState(
    getStartOfWeekForDate(new Date())
  );

  useEffect(() => {
    console.log(chartData);
  }, [chartData]);

  const weekText = "Week of " + weekStartDate.toLocaleDateString();

  const endOfWeek = new Date(
    getShiftedByDaysDate(weekStartDate, 8).getTime() - 1
  );

  const choreStickyColumnSx = {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: 2,
  };

  const choreHeaderSx = {
    borderBottom: 1,
    borderColor: "lightGray",
    position: "sticky",
    top: 0,
    padding: 1,
    background: "white",
    zIndex: 1,
  };

  return (
    <div className={styles.chart}>
      <Box
        id="emptyPlaceholder"
        sx={{
          ...choreHeaderSx,
          ...choreStickyColumnSx,
          zIndex: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={() => {
            setWeekStartDate(getShiftedByDaysDate(weekStartDate, -7));
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography sx={{ flexGrow: 1 }}>{weekText}</Typography>
        <IconButton
          onClick={() => {
            setWeekStartDate(getShiftedByDaysDate(weekStartDate, 7));
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
      {daysOfTheWeek.map((label) => (
        <Typography
          key={label + "HeaderLabel"}
          sx={{
            ...choreHeaderSx,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {label}
        </Typography>
      ))}

      {chartData.map((chore) => {
        return (
          <Fragment key={"fragment" + chore.id}>
            <ChoreControl
              key={"chore" + chore.id}
              sx={choreStickyColumnSx}
              chore={chore}
              onChoreNameChanged={(newName) => {
                dispatch({
                  type: WriteAction.RenameChore,
                  payload: {
                    householdId: currentHousehold.id,
                    choreId: chore.id,
                    choreName: newName,
                  },
                });
              }}
              onChoreDeleted={(choreId) => {
                // setPreviousState(chartData);
                dispatch({
                  type: WriteAction.DeleteChore,
                  payload: {
                    householdId: currentHousehold.id,
                    choreId: choreId,
                  },
                });
              }}
            />
            {chore.entries.map((item, index) => {
              const dayDate = getShiftedByDaysDate(weekStartDate, index);
              const thisWeekEntries = item.filter((entry) => {
                return (
                  entry.time.getTime() >= weekStartDate.getTime() &&
                  entry.time.getTime() <= endOfWeek.getTime()
                );
              });
              return (
                <ChoreDayBox
                  key={"chore" + chore.id + "Day" + index}
                  className={styles.choreBlock}
                  entries={thisWeekEntries}
                  choreId={chore.id}
                  entryDate={dayDate}
                  dayIndex={index}
                  onEntryAdded={(entryTimeStamp, user) => {
                    dispatch({
                      type: WriteAction.AddEntry,
                      payload: {
                        householdId: currentHousehold.id,
                        choreId: chore.id,
                        userId: user.id,
                        timeStamp: entryTimeStamp,
                      },
                    });
                  }}
                />
              );
            })}
          </Fragment>
        );
      })}

      <Box sx={choreStickyColumnSx}>
        <Button
          onClick={() => dispatch(addNewChore(currentHousehold.id, chartData))}
        >
          + Add chore
        </Button>
      </Box>
      {/* <UndoSnackbar
        onClose={() => setPreviousState(null)}
        open={Boolean(previousState)}
        onUndo={() => {
          // dispatch(loadData(previousState));
          // setPreviousState(null);
          console.log("Undo temporarily unavailable");
        }}
        message="Chore deleted"
      /> */}
    </div>
  );
};

export default Chart;
