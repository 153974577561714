import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CirclePicker } from "react-color";
import { useUser } from "../../context/UserContext";
import { defaultUserColor } from "../../sync/FirebaseSync";

const UserSettingsDialog = ({ isOpen, onClose }) => {
  const { signedInUser, updateSignedInUser } = useUser();
  const [currentDisplayName, setCurrentDisplayName] = useState(
    signedInUser.name
  );
  const [currentColor, setCurrentColor] = useState(
    signedInUser.color ? signedInUser.color : defaultUserColor
  );

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <TextField
          label="Display name"
          margin="dense"
          value={currentDisplayName}
          onChange={(event) => {
            setCurrentDisplayName(event.target.value);
          }}
        ></TextField>

        <Box sx={{ margin: 2 }}>
          <Typography>Check color</Typography>
          <CirclePicker
            color={currentColor}
            onChangeComplete={(color, event) => setCurrentColor(color.hex)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            updateSignedInUser({
              name: currentDisplayName,
              color: currentColor,
            });
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSettingsDialog;
