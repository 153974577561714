import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useHouseholdForSignedInUser } from "../../context/HouseholdContext";

const AddHouseholdsDialog = ({ isOpen, onClose }) => {
  const [currentDisplayName, setCurrentDisplayName] = useState();
  const { addHousehold } = useHouseholdForSignedInUser();

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Add a new household</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          margin="dense"
          value={currentDisplayName}
          onChange={(event) => {
            setCurrentDisplayName(event.target.value);
          }}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            addHousehold(currentDisplayName);
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddHouseholdsDialog;
