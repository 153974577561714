import { doc, updateDoc } from "@firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { db, usersCollectionId } from "../firebaseConfig";
import {
  allUsersObservable,
  getUserObservableForUserId,
} from "../sync/FirebaseSync";
import { useAuth } from "./AuthContext";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { authUser } = useAuth();
  const [signedInUser, setSignedInUser] = useState(null);
  const [users, setUsers] = useState();

  useEffect(() => {
    if (!authUser) {
      setSignedInUser(null);
    } else {
      const signedInUserObservableSubscription = getUserObservableForUserId(
        authUser.uid
      ).subscribe((user) => {
        setSignedInUser(user);
      });

      return () => signedInUserObservableSubscription.unsubscribe();
    }
  }, [authUser]);

  useEffect(() => {
    const unsubscribeUserObservable = allUsersObservable.subscribe({
      next: (newUsers) => setUsers(newUsers),
    });
    return unsubscribeUserObservable;
  }, []);

  const updateSignedInUser = async (updateData) => {
    if (!signedInUser) {
      throw new Error("Cannot update user when there is no user signed in");
    }
    const userRef = doc(db, usersCollectionId, signedInUser.id);

    updateDoc(userRef, updateData);
  };

  return (
    <UserContext.Provider
      value={{
        signedInUser,
        updateSignedInUser,
        users,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUser };
