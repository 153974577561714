import {
  collection,
  doc,
  documentId,
  getDoc,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { collection as collectionRx, doc as docRx } from "rxfire/firestore";
import { combineLatest, map, of, shareReplay, switchMap } from "rxjs";
import {
  choresCollectionId,
  db,
  entiresCollectionId,
  householdsCollectionId,
  usersCollectionId,
} from "../firebaseConfig";

const usersCollectionRef = collection(db, usersCollectionId);

const userDocToUser = (userDoc) => {
  return {
    ...userDoc.data(),
    id: userDoc.id,
  };
};

export const allUsersObservable = collectionRx(usersCollectionRef).pipe(
  map((userRefs) => {
    return userRefs.map(userDocToUser);
  }),
  shareReplay(1)
);

export const getUsersInHouseholdObservable = (householdId) => {
  if (!householdId) {
    return of([]);
  }

  const householdRef = doc(db, householdsCollectionId, householdId);
  return docRx(householdRef).pipe(
    switchMap((householdDoc) => {
      const householdUsers = householdDoc?.data()?.users;
      if (!householdUsers) {
        return of([]);
      }
      const householdUsersIds = householdUsers.map(
        (userDocRef) => userDocRef.id
      );

      return getUsersByIdsObservable(householdUsersIds);
    })
  );
};

const getUsersByIdsObservable = (userIds) => {
  if (!userIds || userIds.length === 0) {
    return of([]);
  }

  const usersQuery = query(
    usersCollectionRef,
    where(documentId(), "in", userIds)
  );
  return collectionRx(usersQuery).pipe(
    map((userDocs) => {
      return userDocs.map((userDoc) => {
        return userDocToUser(userDoc);
      });
    })
  );
};

export const getUserObservableForUserId = (userId) => {
  const userDocRef = doc(usersCollectionRef, userId);
  return docRx(userDocRef).pipe(
    map((userDoc) => {
      return userDocToUser(userDoc);
    })
  );
};

export const defaultUserColor = "#4caf50";

export const addUserIfNeeded = async (authUser) => {
  const userDocRef = doc(db, usersCollectionId, authUser.uid);

  try {
    return await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        transaction.set(userDocRef, {
          name: authUser.displayName ? authUser.displayName : authUser.email,
          color: defaultUserColor,
        });
        console.log("User successfully added!");
      }
    });
  } catch (e) {
    console.log("User addition failed: ", e);
    return null;
  }
};

export const getUserById = async (userId) => {
  const userDocRef = doc(db, usersCollectionId, userId);
  const userDoc = await getDoc(userDocRef);
  const user = userDocToUser(userDoc);
  return user;
};

export const getHouseholdsForUserObservable = (userId) => {
  const householdCollection = collection(db, householdsCollectionId);
  const householdForUserQuery = query(
    householdCollection,
    where("users", "array-contains", doc(db, usersCollectionId, userId))
  );
  return collectionRx(householdForUserQuery);
};

export const getChoresAndEntriesForHouseholdObservable = (householdId) => {
  return getChoresForHouseholdObservable(householdId).pipe(
    switchMap((choreDocs) => {
      const choresAndEntryObservables = choreDocs.map((choreDoc) => {
        return getEntriesForChoreDocObservable(choreDoc);
      });

      if (choresAndEntryObservables.length === 0) {
        return of([]);
      }

      // Get a list objects representing each chore and their associated entries
      return combineLatest(choresAndEntryObservables);
    })
  );
};

const getChoresForHouseholdObservable = (householdId) => {
  const choresCollectionRef = collection(
    db,
    householdsCollectionId,
    householdId,
    choresCollectionId
  );
  return collectionRx(choresCollectionRef);
};

// const entryObservablesMap = new Map();

const getEntriesForChoreDocObservable = (choreDoc) => {
  // This gets a collection of all of the entries for a given chore
  const entriesRef = collection(choreDoc.ref, entiresCollectionId);

  // This is an observable that emits when the entries in this collection change.
  //
  // I'm caching the shareable observables so that we don't have to recreate them
  // every time a chore changes
  // let cachedEntriesObservable = entryObservablesMap.get(choreDoc.id);
  // if (!cachedEntriesObservable) {
  const cachedEntriesObservable = collectionRx(entriesRef).pipe(
    map((entries) => convertEntriesRefToDayEntries(entries)),
    shareReplay(1)
  );
  // entryObservablesMap.set(choreDoc.id, cachedEntriesObservable);
  // }
  const choreEntriesObservable = cachedEntriesObservable;

  // This map combines the chore data and entries data into an object,
  // so we end up with an observable that emits the state of the chores and entries
  // any time either of them change
  return choreEntriesObservable.pipe(
    map((dayEntries) => {
      return {
        ...choreDoc.data(),
        id: choreDoc.id,
        entries: dayEntries,
      };
    })
  );
};

const convertEntriesRefToDayEntries = (entries) => {
  const dayEntries = [[], [], [], [], [], [], []];

  entries.forEach((entryRef) => {
    const entry = entryRef.data();
    const entryTime = entry.time;
    const entryDate = new Date(
      entryTime.year,
      entryTime.monthIndex,
      entryTime.dayOfMonth,
      entryTime.hour,
      entryTime.minute
    );
    const dayIndex = entryDate.getDay();

    const domainEntry = {
      time: entryDate,
      userId: entry.user.id,
      id: entryRef.id,
    };

    dayEntries[dayIndex].push(domainEntry);
  });

  return dayEntries;
};
