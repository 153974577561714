import { Typography } from "@mui/material";
import { EmailAuthProvider, getAuth, GoogleAuthProvider } from "firebase/auth";
import { useEffect } from "react";
import { firebaseApp } from "../../firebaseConfig";

const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    EmailAuthProvider.PROVIDER_ID,
  ],
};

const LoginScreen = () => {
  useEffect(() => {
    require("firebaseui/dist/firebaseui.css");
    const firebaseui = require("firebaseui");
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(getAuth(firebaseApp));
    ui.start("#firebaseUiAuthContainer", uiConfig);
  }, []);

  return (
    <div>
      <Typography padding="32px">Please sign in to continue</Typography>
      {/* <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={getAuth(firebaseApp)}
      /> */}
      <div id="firebaseUiAuthContainer" />
    </div>
  );
};

export default LoginScreen;
