import { createContext, useContext, useEffect, useState } from "react";
import { map } from "rxjs";
import {
  getHouseholdsForUserObservable,
  getUsersInHouseholdObservable,
} from "../sync/FirebaseSync";
import { addNewHousehold } from "../sync/FirestoreWrite";
import { useUser } from "./UserContext";

const HouseholdContext = createContext();

export const HouseholdProvider = ({ children }) => {
  const [userId, setUserId] = useState();

  const [availableHouseholds, setAvailableHouseholds] = useState();
  const [currentHousehold, setCurrentHousehold] = useState();
  const [currentHouseholdUsers, setCurrentHouseholdUsers] = useState();

  const addHousehold = (householdName) => {
    addNewHousehold(userId, householdName);
  };

  useEffect(() => {
    if (userId) {
      const householdsSubscription = getHouseholdsForUserObservable(userId)
        .pipe(
          map((householdDocs) => {
            return householdDocs.map((householdDoc) => {
              const domainHousehold = {
                ...householdDoc.data(),
                id: householdDoc.id,
              };
              return domainHousehold;
            });
          })
        )
        .subscribe({
          next: (households) => {
            setAvailableHouseholds(households);
          },
        });

      return () => householdsSubscription.unsubscribe();
    }

    setCurrentHousehold(null);
    setAvailableHouseholds(null);
    return () => {};
  }, [userId]);

  useEffect(() => {
    if (
      currentHousehold &&
      !availableHouseholds.some(
        (household) => household.id === currentHousehold.id
      )
    ) {
      setCurrentHousehold(null);
    }
  }, [availableHouseholds, currentHousehold]);

  useEffect(() => {
    if (currentHousehold?.id) {
      const householdUsersSubscription = getUsersInHouseholdObservable(
        currentHousehold.id
      ).subscribe((users) => setCurrentHouseholdUsers(users));

      return () => householdUsersSubscription.unsubscribe();
    }

    setCurrentHouseholdUsers(null);
  }, [currentHousehold]);

  return (
    <HouseholdContext.Provider
      value={{
        availableHouseholds,
        currentHousehold,
        setCurrentHousehold,
        setUserId,
        currentHouseholdUsers,
        addHousehold,
      }}
    >
      {children}
    </HouseholdContext.Provider>
  );
};

export const useHousehold = (userId) => {
  const context = useContext(HouseholdContext);
  if (context === undefined) {
    throw new Error("useHousehold must be used within a HouseholdProvider");
  }
  useEffect(() => {
    context.setUserId(userId);
  }, [userId]);
  return context;
};

export const useHouseholdForSignedInUser = () => {
  const { signedInUser } = useUser();
  return useHousehold(signedInUser?.id);
};
