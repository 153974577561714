import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import MoreVert from "@mui/icons-material/MoreVert";
import Check from "@mui/icons-material/Check";
import { useState } from "react";
import styles from "./styles.module.css";

const EditChoreName = ({ initialChoreName, onFinishedEditing }) => {
  const initialName = initialChoreName ? initialChoreName : "";
  const [choreName, setChoreName] = useState(initialName);

  return (
    <>
      <TextField
        inputRef={(input) => {
          // This is an attempt to autofocus, but it doesn't work
          // console.log(input);
          if (input != null) {
            input.focus();
          }
        }}
        value={choreName}
        onChange={(event) => setChoreName(event.target.value)}
      />
      <IconButton onClick={() => onFinishedEditing(choreName)}>
        <Check />
      </IconButton>
    </>
  );
};

const ChoreControl = ({
  chore,
  onChoreNameChanged,
  onChoreDeleted,
  sx = null,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);

  const [isEditingName, setIsEditingName] = useState(chore.name === null);

  const choreControlSx = {
    display: "flex",
    alignItems: "center",
    borderRight: 1,
    borderBottom: 1,
    borderLeft: 1,
    borderColor: "lightGray",
    padding: 1,
  };

  return (
    <Box sx={{ ...choreControlSx, ...sx }}>
      {isEditingName ? (
        <EditChoreName
          initialChoreName={chore.name}
          onFinishedEditing={(newChoreName) => {
            onChoreNameChanged(newChoreName);
            setIsEditingName(false);
          }}
        />
      ) : (
        <>
          <Typography className={styles.choreLabel}>{chore.name}</Typography>
          <IconButton
            onClick={(event) => setAnchorElement(event.currentTarget)}
          >
            <MoreVert />
          </IconButton>
          <Menu
            open={open}
            onClose={() => setAnchorElement(null)}
            anchorEl={anchorElement}
          >
            <MenuItem
              onClick={(event) => {
                event.preventDefault();
                setIsEditingName(true);
                setAnchorElement(null);
              }}
            >
              Rename
            </MenuItem>
            <MenuItem
              onClick={() => {
                onChoreDeleted(chore.id);
                setAnchorElement(null);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};

export default ChoreControl;
